.ui.small.table {
    font-size: 0.8em !important;
}

.pdframe {
    width: 80%;
    min-height : 800px;
}

.cfmLabel{
    margin-top: 2px !important;
}

.spacer {
    height: 80px;
}

.exchangeicon {
    padding-left: 5px; 
}

.popupCls {
    display: flex;
}

.rptButton {
    margin-top: 5px !important;
}

.miniinput {
    width: 8em !important;
    min-width: 8em !important;
}

.tinyinput {
    width: 5em !important;
}

.tinyheader {
    width: 2% !important;
}

.description {
    word-break: break-word !important;
    white-space:pre-wrap;
}