.flatpickr-calendar,
.flatpickr-calendar > div {
    height: auto;
}

.receiveForm {
    text-align: left;
}

.deleteButton {
    height:40%; 
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}
.userName {
    opacity: 1 !important;
}
.userName > label{
    opacity: 1 !important;
}

.userName > .input{
    opacity: 1 !important;
    font-size: 150% !important;
}

.userButton{
margin-top: 30% !important;
margin-bottom: 30% !important;
}
    .copyButton {
        height:40%; 
        margin-top: 10px !important;
        margin-bottom: 10px !important;
        }
    

.buttongrp{
    display: block;
    height: 80%;
    margin-top: auto !important;
    margin-bottom: 0px !important;
}

.labelInput > .input {
    font-size: 80% !important;
    height: 50%;
    margin-top: auto !important;
    margin-bottom: auto !important;
    opacity: 1 !important;
}

.labelInput {
    opacity:1 !important;
}

td {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
}

.actionArea {
    display: flex;
}

.formButton .button {
    margin-top: 30% !important;
}

.mainseg {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    border: none !important;
}