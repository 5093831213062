.TestBlock {
    margin-top: 10px 
    
}

.modal 
{    height: auto;
display: block;
 }

 .custField.input {
    width: 50%;
    margin-bottom:5px;
   }

   .gridField.input {
    width: 90%;
    margin-bottom:5px;
   }

.rmvButt {
    display: block !important;
    margin-bottom: 5px !important;
}

.txtField {
 width: 100%;
 margin-bottom:10px;
 padding: 10px;
}

.addheader {
    margin-bottom:10px !important;
}

.custHeader {
    margin-bottom: 5px !important;
}

.buttonCell {
    display: flex
}

.showdeleted {
    float: right;
}

.addNew {
    float: left;
    margin-bottom: 10px !important;
}