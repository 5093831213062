.formButton {
    height:30%; 
    display:flex !important;
    /* margin-top: 10px !important;
    margin-bottom: -10px !important; */
}


.supplyForm {
    text-align: left;
}

.uneditable {
    opacity: 1 !important;
    pointer-events: none !important;
}

.ui.celled.table tr td:first-child, .ui.celled.table tr th:first-child {
   border-left: 1px solid rgba(34,36,38,.1) !important;
}