.flatpickr-calendar,
.flatpickr-calendar > div {
    height: auto;
}

.reviewForm {
    text-align: left;
}

.search-button {
    height: 30% !important;
    margin-top: auto !important;
}

.ui.small.table {
    font-size: .8em !important;
}

.ui.dropdown .menu > .item {
    font-size: 1em !important;
}

.editIcon {
    float:right !important;
}

.descInput {
    width: 100% !important;
}
.checkbox {
    vertical-align:middle !important;
    margin-right: 2px;
}

.newInput {
    width: auto;
}

.editicon {
    float: right;
    vertical-align:middle !important;
}