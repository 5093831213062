.TestBlock {
    margin-top: 10px 
    
}

.modal 
{    height: auto;
display: block;
 }

 .nameField.input {
    width: 50%;
    margin-top: 10px;
    margin-bottom: 10px;
   }

 .nameField>.label {
     width: 30%;
 }

 .testGroup {
    margin-top: 10px !important; 
    margin-bottom: 10px !important;
 }

.passField.input {
 width: 50%;
  margin-bottom: 10px;
}
.passField>.label {
    width: 30%;
}

.methodField.input {
    margin-bottom:10px;
}

.addNew {
    float: left;
    margin-bottom: 10px !important;
}