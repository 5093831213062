.spacer {
    height: 80px;
}

.exchangeicon {
    padding-left: 5px; 
}

.popupCls {
    display: flex;
}