
.corpHeader {
    margin: auto;
    width: 25%;
vertical-align: middle ;
}

.uName {
    margin: auto;
    width: 25%;
}

.loginTag {
    border : 0px !important;
}